/**
 * @TODO 全局常量配置
 */
import packageInfo from '../../package.json';

export const COMPANY = packageInfo.company;

export const APP_VERSION = packageInfo.version;

export const APP_TITLE = '易检测-物资管理';

export const ICON_FONT_SCRIPTURL =
  '//at.alicdn.com/t/c/font_3701640_flhq6796crc.js';

export const QUERY_SUCCESS_MSG = '操作成功!';

/**
 * @TODO Code定义
 */
export const codeMessage: Record<number, string> = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * @TODO 全局 Code 定义
 */
export const codeDefinition: {
  QUERY_SUCCESS: number;
  CLIENT_REQUEST_PARAMS_ERROR: number;
  UNAUTHORIZED_ERROR: number;
  RESOURCE_NOT_FOUND: number;
  INTERNAL_SERVER_ERROR: number;
} = {
  QUERY_SUCCESS: 200,
  CLIENT_REQUEST_PARAMS_ERROR: 400,
  UNAUTHORIZED_ERROR: 401,
  RESOURCE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};
