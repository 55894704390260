/*
 * @Author: jianglinfeng
 * @objectDescription: 采购申请
 * @Date: 2022-11-14 16:28:48
 * @LastEditors: LGX
 * @LastEditTime: 2024-04-09 17:24:04
 * @FilePath: \ejc-resource-management-webui\src\api\purchase.ts
 * @Version:
 */
import request from '@/utils/request';

export enum API {
  // 根据字段名称模糊查询
  GET_SELECT_PRODUCT = '/purchase/listPurchase',
  // 采购申请表单
  POST_ADD_PRODUCT = '/purchase/addPurchase',
  //上传附件
  POST_PRODUCT_UPDATEFILE = '/purchase/updateFile',
  //生成询价单
  GET_SELECTRFQ = '/purchase/selectRFQ',
  //常购物品
  GET_SELECTFREQUENT_SHOPP = '/purchase/frequentPurchaseDetails',
  //根据订单编号查询供应商
  GET_ORDERNOSUPPLIER = '/purchase/orderNoSupplier',
  //根据订单编号查询采购明细接口
  GET_LISTSUPPLIERNAME = '/purchase/listSupplierName',
  //物资列表查询接口
  GET_LIST_MATERIAL = '/purchase/selectPurchaseApprovalVo',
  //审批同意或驳回
  POST_APPROVAL_STATE = '/purchase/approvalState',
  //根据任务清单查询采购详情
  GET_SELECT_PURCHASE_ORDER = '/purchaseOrder/selectPurchaseOrder',
  //采购订单新增接口
  POST_ADD_PURCHASE_ORDER = '/purchaseOrder/addPurchaseOrder',
  //采购终止接口
  POST_UNDATE_ORDER_END = '/purchaseOrder/updateOrderEnd',
  // 获取采购申请草稿
  GET_SAVE_DRAFT = '/purchase/queryPurchaseDraft',
  // 删除采购申请草稿箱物资
  DELETE_DRAFT_MATERIAL = '/purchase/deletePurchaseDetail',
  // 待入库查询
  QUERY_RECEIPTS_DETAILS = '/purchaseReceipts/selectReceiptsByNo',
  // 待入库提交数据
  SUBMIT_RECEIPTS_DETAILS = '/purchaseReceipts/confirmReceipt',
  // 入库操作提交
  SUBMIT_RECEIPT_IMPORT = '/purchaseReceipts/receiptImport',
  // 获取领用单详情
  QUERY_RECEIPT__DETAILS = '/receiveBill/selectReceiveBillAndDetails',
  // 领用单出库操作
  SUBMIT_RECEIPT_OUT_STOCK = '/inventory/outStore',
  // 再来一单功能获取当前单据编号的物资列表
  QUERY_COPY_PURCHASE_REQUEST = '/purchase/copyPurchaseDetails/',
  // 领用单审批同意
  POST_AGREE_RECEIVE = '/receiveBill/agree',
  // 领用审批驳回
  POST_REJECT_RECEIVE = '/receiveBill/refuse',
  // 待价格确认驳回
  POST_INQUIRY_REJECT = '/purchase/rejectPrice',
  // 关闭领用单
  POST_REJECT_DELIVERY = '/receiveBill/stop',
  // 同步物资基础信息
  POST_MATERIAL_INFO_SYNC = '/purchase/paceMaterial',
  // 获取历史价格信息
  GET_UNIT_PRICE_HISTORY_INFO = '/purchase/historyPrice',
  // 采购待收货/待入库驳回
  POST_REJECT_STORAGE_STOCK = '/purchaseReceipts/rejectReceipt',
  // 领用单物资退回
  POST_RECEIVE_BACK = '/receiveBill/backBillDetails',
  // 领用单退回记录
  GET_RECEIVE_BACK_RECORD = '/receiveBill/backLog',
  // 获取拆箱详情
  GET_UNPACK_DETAIL = '/purchaseReceipts/receiptUnpackLog',
  // 保存拆箱
  POST_SAVE_UNPACK = '/purchaseReceipts/receiptUnpack',
  // 待入库保存
  POST_SAVE_STORAGE = '/purchaseReceipts/updateReceiptsDetails',
  // 清除拆箱数据
  POST_CLEAR_UNPACK = '/purchaseReceipts/clearUnpackLog',
  // 待入库-拆分物资
  POST_SPLIT_RECEIPTS = '/purchaseReceipts/splitReceipts',

  // 待入库变更申请 审批/驳回
  POST_ALTER_APPROVAL = '/purchaseReceipts/changeAudite',
  // 待入库物资变更提交
  POST_SUBMIT_ALTER = '/purchaseReceipts/changeReceipt',
  // 待出库物资可变更的物资列表
  GET_EXPORT_ALTER_LIST = '/receiveBill/selectModifyList',
  // 待出库物质变更
  POST_EXPORT_ALTER = '/receiveBill/modifyMaterial',
  // 库存列表添加采购物资草稿
  POST_ADD_PURCHASE = '/purchase/addToPurchase',
  // 采购订单审批通过
  POST_PURCHASE_ORDER_AGREE = '/purchaseOrder/updateOrderAgree',
  // 采购订单审批驳回
  POST_PURCHASE_ORDER_REJECT = '/purchaseOrder/updateOrderRefuse',
  // 已处理-领用出库单-下载/预览
  GET_EXPORT_FORM_WAREHPUSE = '/receiveBill/getExportForm',
  // 分批收货-待入库查询
  QUERY_RECEIPTS_BATCH_DETAILS = '/purchaseReceipts/batchSelectReceiptsByNo',
  // 待入库-分批收货-提交数据
  SUBMIT_RECEIPTS_BATCH_DETAILS = '/purchaseReceipts/batchReceipt',
  // 生成供应商比价信息
  SUBMIT_ADD_PRICE_COMPARE = '/priceCompare/addPriceCompare',


  GET_INFO_LIST = '/priceCompare/queryInfoList/',

  GET_COMPREA_INFO = '/priceCompare/queryPriceCompareInfoDetailsVo',


  // 生成分享链接

  GET_SHARE_URL = '/priceCompare/generateShareUrl',

  // 分享页面-根据id查询比价详情
  // GET_SHARE_URL = '/priceCompare/generateShareUrl',

  // /priceCompare/'/queryPriceCompareInfoDetailsVo/

}
// 根据字段名称模糊查询
export type FlowableModelResultModel = {
  id: string;
  modelName: string;
  modelCategory: string;
  modelRemark: string;
  createTime: string;
};

export const selectProduct = async (params: { materialName: string }) =>
  request.get(API.GET_SELECT_PRODUCT, { params });

//采购申请表
export type AddProduct = {
  productInfo: {
    applyNo?: string;
    telephone: string;
    applyTime: string;
    applyDept: string;
    applyReason: string;
    rremark?: string;
    fileId?: string;
  };
  productDetails: {
    id: number;
    productName?: string;
    productType?: string;
    productBrand?: string;
    productModel?: string;
    supplier?: string;
    manufacturer?: string;
    quantityUnit?: string;
    quantity?: string;
    latestUnitPrice?: string;
    unitPrice?: string;
    totalPrice?: string;
    remark?: string;
  }[];
};
export const addProduct = async (data: AddProduct) =>
  request.post(API.POST_ADD_PRODUCT, { data });

//上传附件
export type UpdateFileType = {
  file: string;
};

export const updateFile = async (data: UpdateFileType) =>
  request.post(API.POST_PRODUCT_UPDATEFILE, { data });

//生成询价单
export const selectRFQ = async (params: any) =>
  request.get(API.GET_SELECTRFQ, { params });

//常购物品
export const selectFrequentShopp = async (params: any) =>
  request.get(API.GET_SELECTFREQUENT_SHOPP, { params });

//物资列表查询接口
export const getListMaterial = async (params: any) => {
  return request.get(API.GET_LIST_MATERIAL + `/${params.id}`);
};

//审批同意或驳回
export const approvalStare = async (data: {
  productInfo: {
    id: number; //id
    applyState: string; //1 审核通过 2 审核不通过
    totalAmount: string; //合计金额
    fileUrl: string; //附件
  };
  productDetails: Array<Object>;
}) => request.post(API.POST_APPROVAL_STATE, { data });

//根据任务清单查询采购详情
export const getSelectPurchaseOrder = async (id: string) =>
  //TODO:id写死123456可查询数据
  request.get(API.GET_SELECT_PURCHASE_ORDER + `/${id}`);

//采购订单新增
export const AddPurchaseOrder = async (data: {
  purchaseOrderVo: Object;
  purchaseOrderDetailsVoList: Array<Object>;
  taskId: string;
}) => request.post(API.POST_ADD_PURCHASE_ORDER, { data });

//采购终止
export const StopOrderTask = async (data: {
  id: number;
  content: string;
  taskId: string;
  orderNo: string;
}) => request.post(API.POST_UNDATE_ORDER_END, { data });

// 获取采购申请草稿
export const GetDraft = async () => {
  return request.get(API.GET_SAVE_DRAFT);
};

/**
 * @description 删除采购申请草稿箱物资
 */
export const queryDelDraftMaterial = async (id: number) =>
  request.delete(API.DELETE_DRAFT_MATERIAL + '/' + id);

/**
 * @description 待入库类型单据查询
 */
export const queryReceiptsDetails = async (id: string, params?: any) =>
  request.get(`${API.QUERY_RECEIPTS_DETAILS}/${id}`, { params });

/**
 * @description 待入库提交数据
 */
export const submitReceiptsDetails = async (data: Record<string, any>) =>
  request.post(API.SUBMIT_RECEIPTS_DETAILS, { data });

/**
 * @description 入库操作提交
 */
export const submitReceiptImportDetails = async (data: Record<string, any>) =>
  request.post(API.SUBMIT_RECEIPT_IMPORT, { data });

/**
 * @description 获取领用单详情
 */
export const queryReceiveDetails = async (id: string) =>
  request.get(`${API.QUERY_RECEIPT__DETAILS}/${id}`);

/**
 * @description 领用单出库操作
 */
export const submitReceiptOutStore = async (data: Record<string, any>) =>
  request.post(API.SUBMIT_RECEIPT_OUT_STOCK, { data });

/**
 * @description 再来一单获取物资列表
 */
export const queryCopyPurchaseRequestList = async (
  params: Record<string, string>
) => request.get(API.QUERY_COPY_PURCHASE_REQUEST, { params });

/**
 * @interface 领用单审批参数类型
 */
export interface IApprovalReceiveParams {
  taskId: string;
  comment: string;
  billId: number;
}
/**
 * @description 领用单审批同意
 */
export const queryAgreeReceive = async (data: IApprovalReceiveParams) =>
  request.post(API.POST_AGREE_RECEIVE, { data });

/**
 * @description 领用单审批驳回
 */
export const queryRejectReceive = async (data: IApprovalReceiveParams) =>
  request.post(API.POST_REJECT_RECEIVE, { data });

/**
 * @interface 待价格确认驳回参数类型
 */
export interface IInquiryRejectParams {
  taskId: string;
  purchaseId: number;
  purchaseNo: string;
  comment: string;
}

/**
 * @description 待价格确认驳回
 */
export const queryInquiryReject = async (data: IInquiryRejectParams) =>
  request.post(API.POST_INQUIRY_REJECT, { data });

/**
 * @interface 关闭领用单参数
 */
export interface IRejectDeliveryParams {
  taskId: string;
  comment: string;
  billId: number;
}
/**
 * @description 关闭领用单
 */
export const queryRejectDelivery = async (data: IRejectDeliveryParams) =>
  request.post(API.POST_REJECT_DELIVERY, { data });

/**
 * @interface 同步物资基础信息参数
 */
export interface IMaterialBaseInfoSyncParams {
  type: number;
  applyNo: string;
  materialNo: boolean;
  categoryId: boolean;
  materialName: boolean;
  specs: boolean;
  unit: boolean;
  storeCondition: boolean;
  ids: number[];
}
/**
 * @description 同步物资基础信息
 */
export const queryMaterialBaseInfoSync = async (
  data: IMaterialBaseInfoSyncParams
) => request.post(API.POST_MATERIAL_INFO_SYNC, { data });

/**
 * @interface 获取历史价格信息参数
 */
export interface IUnitPriceHistoryInfoParams {
  materialNo: string;
  supplier?: string;
  orderTime: number;
}
/**
 * @description 获取历史价格信息
 */
export const queryUnitPriceHistoryInfo = async (
  params: IUnitPriceHistoryInfoParams
) => request.get(API.GET_UNIT_PRICE_HISTORY_INFO, { params });

/**
 * @interface 采购待收货/待入库驳回参数
 */
export interface IRejectStorageStockParams {
  taskId: string;
  receiptsId: number;
  receiptsNo: string;
  comment: string;
}
/**
 * @description 采购待收货/待入库驳回
 */
export const queryRejectStorageAndStock = async (
  data: IRejectStorageStockParams
) => request.post(API.POST_REJECT_STORAGE_STOCK, { data });

/**
 * @interface 领用单退回参数
 */
export interface IReceiveBackParams {
  billNo: string;
  reason: string;
  list: number[];
}
/**
 * @description 领用单退回
 */
export const queryReceiveBack = async (data: IReceiveBackParams) =>
  request.post(API.POST_RECEIVE_BACK, { data });

/**
 * @description 领用单退回记录
 */
export const queryReceiveBackRecord = async (params: {
  billNo: string;
  id: number;
}) => request.get(API.GET_RECEIVE_BACK_RECORD, { params });

/**
 * @description 获取拆箱详情
 */
export const queryUnpackDetail = async (params: {
  receiptNo: string;
  materialId: number;
}) => request.get(API.GET_UNPACK_DETAIL, { params });

/**
 * @description 保存拆箱
 */
export const querySaveUnpack = async (data: any) =>
  request.post(API.POST_SAVE_UNPACK, { data });

/**
 * @description 待入库保存
 */
export const querySaveStorage = async (data: {
  purchaseReceiptsDetails: any[];
  purchaseReceipts: Record<string, any>;
}) => request.post(API.POST_SAVE_STORAGE, { data });

/**
 * @interface 清除拆箱数据参数
 */
export interface IClearUnpackParams {
  receiptNo: string;
  materialId: number;
}

/**
 * @description 清除拆箱数据
 */
export const queryClearUnpack = async (data: IClearUnpackParams) =>
  request.post(API.POST_CLEAR_UNPACK, { data });

/**
 * @interface 拆分物资参数
 */
export interface ISplitReceiptsParams {
  receiptNo: string;
  id: number;
  splitNum: number;
  details: {
    importAmount: number;
  }[];
  matterNo: string;
}

/**
 * @description 拆分物资
 */
export const querySplitReceipts = async (data: ISplitReceiptsParams) =>
  request.post(API.POST_SPLIT_RECEIPTS, { data });

/**
 * @interface 待入库变更申请 审批/驳回 参数
 */
export interface IAlterApprovalParams {
  taskId: string;
  flag: number; // 1 审批通过 0 驳回
  receiptNo: string;
  changeReview: string;
}
/**
 * @description 待入库变更申请 审批/驳回
 */
export const queryAlterApproval = async (data: IAlterApprovalParams) =>
  request.post(API.POST_ALTER_APPROVAL, { data });

/**
 * @interface 待入库物资变更操作参数
 */
export interface ISubmitAlterParams {
  changeId: number;
  receiptNo: string;
  [key: string]: any;
}
/**
 * @description 待入库物资变更操作
 */
export const querySubmitAlter = async (data: ISubmitAlterParams) =>
  request.post(API.POST_SUBMIT_ALTER, { data });

/**
 * @interface 待出库物资可变更的物资列表参数
 */
export interface IExportAlterListParams {
  materialNo: string;
  skuId: string;
}
/**
 * @description 待出库物资可变更的物资列表
 */
export const queryExportAlterList = async (params: IExportAlterListParams) =>
  request.get(API.GET_EXPORT_ALTER_LIST, { params });

/**
 * @interface 待出库物质变更参数
 */
export interface IExportAlterParams {
  id: number;
  skuId: string;
}
/**
 * @description 待出库物质变更
 */
export const queryExportAlter = async (data: IExportAlterParams) =>
  request.post(API.POST_EXPORT_ALTER, { data });

/**
 * @interface 库存列表添加采购物资草稿参数
 */
export interface IAddPurchaseParams {
  productInfo: Record<string, any>;
  productDetails: Record<string, any>;
}
/**
 * @description 库存列表添加采购物资草稿
 */
export const queryAddPurchase = async (data: IAddPurchaseParams) =>
  request.post(API.POST_ADD_PURCHASE, { data });

/**
 * @interface 采购订单审批参数
 */
export interface IPurchaseOrderApprovalParams {
  orderNo: string;
  taskId: string;
  content?: string;
  id: number
}
/**
 * @description 采购订单审批通过
 */
export const queryPurchaseOrderAgree = async (
  data: IPurchaseOrderApprovalParams
) => request.post(API.POST_PURCHASE_ORDER_AGREE, { data });

/**
 * @description 采购订单审批驳回
 */
export const queryPurchaseOrderReject = async (
  data: IPurchaseOrderApprovalParams
) => request.post(API.POST_PURCHASE_ORDER_REJECT, { data });

/**
 * @description 已处理-领用出库单-下载/预览
 */
export const getExportFormWarehouse = async (params: any
) => request.get(API.GET_EXPORT_FORM_WAREHPUSE, { params });


export const addPriceCompare = async (data: any
  ) => request.post(API.SUBMIT_ADD_PRICE_COMPARE, { data });


/**
 * @description 已处理-领用出库单-下载/预览
 */
 export const queryInfoList = async (id: any
  ) => request.get(API.GET_INFO_LIST+ id);
  

/**
 * @description 分批收货-待入库类型单据查询
 */
export const queryReceiptsBatchDetails = async (id: string, params?: any) =>
  request.get(`${API.QUERY_RECEIPTS_BATCH_DETAILS}/${id}`, { params });

/**
 * @description 待入库-分批收货-提交数据
 */
export const submitReceiptsBatchDetails = async (data: Record<string, any>) =>
  request.post(API.SUBMIT_RECEIPTS_BATCH_DETAILS, { data });

/**
 * @description 已处理-领用出库单-下载/预览
 */
 export const generateShareUrl = async (params: any
  ) => request.get(API.GET_SHARE_URL, { params });

  export const queryPriceCompareInfoDetailsVo = async (params: any) =>
  request.get(API.GET_COMPREA_INFO, { params });