/**
 * @description 模版采用了Zustand作为状态管理工具，该工具具有体积小、无需模版代码，无需 Provider 等特点
 * 更少的样板代码
 * Zustand 只在 state 的值改变时渲染组件, 通常可以处理状态的改变而无需渲染代码
 * 状态管理通过简单定义的操作进行集中和更新, 在这方面和 Redux 类似, 但是又和 Redux 不太类似, Redux 开发必须创建 reducer、action、dispatch来处理状态, Zustand 让它变得更加容易
 * 使用 hooks 来管理 states, Hooks 在 react 中很流行, 因此是一个很受欢迎的状态管理库
 * Zustand 使用简单使用和简单实现的代码
 * 通过消除使用 Context Provides 从而使代码更短、更易读
 */
import { lazy } from 'react';
import { message } from 'antd';
import { GetDraft } from '@/api/purchase';
import { queryPurchaseOrdersCount } from '@/api/repertory';
import { codeDefinition } from '@/constants';
import { AllRouteMappings, InitialRoutes } from '@/routes';
import { cloneDeep } from 'lodash';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { ClassifyItemProperties } from '@/pages/ClassifyTree/components/ClassifyConfig';
import { materialFormType } from '@/pages/Materials/MaterialCatalogue/components/typing';

const PageLayout = lazy(() => import('@/layouts/PageLayout'));

/**
 * @TODO 定义验证的 Token 变量
 */
export const useTokenStore = create<{
  token: string;
  setToken: (_token: string) => void;
}>()(
  devtools(
    persist(
      (set, get) => ({
        token: '',
        setToken: (_token: string) => set(() => ({ token: _token })),
      }),
      {
        name: 'token-storage',
        getStorage: () => sessionStorage,
      }
    )
  )
);

/**
 * @TODO 定义切换主题变量
 */
export const useThemeStore = create<{
  prefix: string;
  setPrefix: (_prefix: string) => void;
}>()(
  devtools(
    persist(
      (set, get) => ({
        prefix: 'default',
        setPrefix: (_prefix) => set(() => ({ prefix: _prefix })),
      }),
      {
        name: 'theme-storage',
      }
    )
  )
);

/**
 * @TODO 定义用户信息
 */
export const useInfoStore = create<{
  userInfo: Record<string, any>;
  setUserInfo: (_userInfo: Record<string, any>) => void;
}>()(
  devtools(
    persist(
      (set, get) => ({
        userInfo: {},
        setUserInfo: (_userInfo) => set(() => ({ userInfo: _userInfo })),
      }),
      {
        name: 'ejc_user_info',
        getStorage: () => sessionStorage,
      }
    )
  )
);

type TClaimOrdersModalStoreProps = {
  claimOrdersModalIsOpen: boolean;
  triggerClaimOrdersModalIsOpen: (_isShow: boolean) => void;
};

/**
 * @TODO 标志申领单待出库Modal是否显示
 */
export const useClaimOrdersModalStore = create<TClaimOrdersModalStoreProps>(
  (set) => ({
    claimOrdersModalIsOpen: false,
    triggerClaimOrdersModalIsOpen: (_claimOrdersModalIsOpen: boolean) =>
      set({ claimOrdersModalIsOpen: _claimOrdersModalIsOpen }),
  })
);

type TReceiptOutOrdersTypeProps = {
  receiptOutModalType: string;
  triggerReceiptOutModalType: (_receiptOutModalIsOpen: string) => void;
};

/**
 * @TODO 判断出库类型 1是申请单待出库，2是其它出库
 */
export const useReceiptOutOrdersTypeStore = create<TReceiptOutOrdersTypeProps>(
  (set) => ({
    receiptOutModalType: '2',
    triggerReceiptOutModalType: (_receiptOutModalType: string) =>
      set({ receiptOutModalType: _receiptOutModalType }),
  })
);

type TReceiptOutOrdersStoreProps = {
  receiptOutModalIsOpen: boolean;
  triggerReceiptOutModalIsOpen: (_receiptOutModalIsOpen: boolean) => void;
};

/**
 * @TODO 领用出库单 Drawer
 */
export const useReceiptOutOrdersStore = create<TReceiptOutOrdersStoreProps>(
  (set) => ({
    receiptOutModalIsOpen: false,
    triggerReceiptOutModalIsOpen: (_receiptOutModalIsOpen: boolean) =>
      set({ receiptOutModalIsOpen: _receiptOutModalIsOpen }),
  })
);

type TReceiveApplicationOrdersStoreProps = {
  receiveAppIsOpen: boolean;
  triggerReceiveAppIsOpen: (_receiveAppIsOpen: boolean) => void;
};

/**
 * @TODO 领用申请单 Drawer
 */
export const useReceiveApplicationOrdersStore =
  create<TReceiveApplicationOrdersStoreProps>((set) => ({
    receiveAppIsOpen: false,
    triggerReceiveAppIsOpen: (_receiveAppIsOpen: boolean) =>
      set({ receiveAppIsOpen: _receiveAppIsOpen }),
  }));

type TSelectedClassifyStoreProps = {
  selectedClassify: Partial<ClassifyItemProperties>;
  setSelectedClassify: (
    _selectedClassify: Partial<ClassifyItemProperties>
  ) => void;
};

/**
 * @TODO 设置当前已选择的分类
 */
export const useSelectedClassifyStore = create<TSelectedClassifyStoreProps>(
  (set) => ({
    selectedClassify: {},
    setSelectedClassify: (_selectedClassify: Partial<ClassifyItemProperties>) =>
      set({ selectedClassify: _selectedClassify }),
  })
);

type TReceiveOrdersMaterialTotalStoreProps = {
  materialTotal: number;
  setMaterialTotal: (_materialTotal: number) => void;
};

/**
 * @TODO 当前用户领用单资源品种数量
 */
export const useReceiveOrdersMaterialTotal =
  create<TReceiveOrdersMaterialTotalStoreProps>((set) => ({
    materialTotal: 0,
    setMaterialTotal: (_materialTotal: number) =>
      set({ materialTotal: _materialTotal }),
  }));

// 领用单数量
export const useReceiveOrdersCount = create<{
  receiveOrdersCount: number;
  setReceiveOrdersCount: (_materialTotal: number) => void;
}>((set) => ({
  receiveOrdersCount: 0,
  setReceiveOrdersCount: (_count: number) =>
    set({ receiveOrdersCount: _count }),
}));

// 采购订单数量
export const usePurchaseOrdersCount = create<{
  purchaseOrdersCount: number;
  purchaseOrderCountFetch: (categoryType: number) => void;
}>((set) => ({
  purchaseOrdersCount: 0,
  purchaseOrderCountFetch: async (categoryType) => {
    try {
      const { code, data, msg } = await queryPurchaseOrdersCount({
        categoryType,
      });
      if (code === codeDefinition.QUERY_SUCCESS) {
        set({ purchaseOrdersCount: data });
      } else {
        message.error(msg);
      }
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  },
}));

/**
 * @description: 任务中心上标
 * @return {*}
 */
type TTaskCenterStoreProps = {
  pending: number;
  setPending: (pending: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void; //
  // dealWith:number;
  // setDealWith: (dealWith: number) => void;
  // applied:number;
  // setApplied:(applied: number) => void;
};
export const useTaskCenter = create<TTaskCenterStoreProps>()((set) => ({
  pending: 0,
  setPending: (_pending: number) => set(() => ({ pending: _pending })),
  isLoading: true,
  setIsLoading: (_isLoading: boolean) => set(() => ({ isLoading: _isLoading })),
  // dealWith:0,
  // setDealWith: (_dealWith: number) => set(() => ({ dealWith: _dealWith })),
  // applied:0,
  // setApplied:(_applied: number) => set(() => ({ applied: _applied })),
}));

/**
 * @TODO 当前申领单明细填充到出库单的物资明细列表
 */
export const useOutWarehouseMaterialDetailsStoreProps = create<
  Record<string, any>
>((set) => ({
  outWarehouseMaterialDetail: {
    userName: '',
    detailList: [],
  },
  setOutWarehouseMaterialDetaiil: (_outWarehouseMaterialDetail: any) =>
    set({ outWarehouseMaterialDetail: _outWarehouseMaterialDetail }),
}));

/**
 * @TODO 库存明细页面入库操作之后是否刷新表格数据
 */
export const useRefreshTableDataStoreProps = create<{
  isRefreshTableData: boolean;
  setIsRefreshTableData: (_isRefreshTableData: boolean) => void;
}>((set) => ({
  isRefreshTableData: false,
  setIsRefreshTableData: (_isRefreshTableData: boolean) =>
    set(() => ({ isRefreshTableData: _isRefreshTableData })),
}));

type TMaterialDetailStoreProps = {
  materialDetails: Partial<materialFormType>;
  setMaterialDetails: (_materialDetail: Partial<materialFormType>) => void;
};

/**
 * @TODO 设置当前已选择的分类
 */
export const useMaterialDetailsStore = create<TMaterialDetailStoreProps>(
  (set) => ({
    materialDetails: {},
    setMaterialDetails: (_materialDetail: Partial<materialFormType>) =>
      set({ materialDetails: _materialDetail }),
  })
);

// 创建当前业务模块共享的数据对象
export const useMaterialsModalStore = create<{
  getRecevieOrdersCount: () => void | null;
  setGetRecevieOrdersCount: (_getRecevieOrdersCount: () => void) => void;
  tableDataReload: () => void | null;
  setTableDataReload: (_tableDataReload: () => void) => void;
  materialCategoryType: number;
  setMaterialCategoryType: (_materialCategoryType: number) => void;
}>((set) => ({
  // 获取出库单据数量方法
  getRecevieOrdersCount: null as any,
  setGetRecevieOrdersCount: (_getRecevieOrdersCount: () => void) =>
    set({ getRecevieOrdersCount: _getRecevieOrdersCount }),
  // 表格数据重加载
  tableDataReload: null as any,
  setTableDataReload: (_tableDataReload: () => void) =>
    set({ tableDataReload: _tableDataReload }),
  // 设置物资分类类型 type
  materialCategoryType: 0,
  setMaterialCategoryType: async (_materialCategoryType: number) =>
    set({ materialCategoryType: _materialCategoryType }),
}));

/**
 * @type allRouter
 */
export type TAllRouter = {
  name: string;
  title: string;
  path: string;
  completePath: string;
  parentPath: string;
  icon: string;
};
/**
 * @TODO 权限路由
 */
export const usePermissionRouterStore = create<{
  allRouter: TAllRouter[];
  setAllRouter: (payload: any) => void;
  routeStructure: any;
  setRouteStructure: (payload: any) => void;
}>()(
  devtools(
    persist(
      (set) => ({
        allRouter: [],
        setAllRouter: (payload: any) => {
          // 生成扁平化的可访问的路由
          const arr = getRouterPath(payload, '');
          set({ allRouter: arr });
        },
        routeStructure: cloneDeep(InitialRoutes),
        setRouteStructure: (payload: any) => {
          set({ routeStructure: payload });
        },
      }),
      {
        name: 'ejc_permission_router',
        getStorage: () => sessionStorage,
        deserialize: (str: string) => {
          const data = JSON.parse(str);
          let routeStructure = data.state.routeStructure;
          routeStructure = deserializeRouter(routeStructure);
          // console.log(routeStructure, AllRouteMappings);

          return {
            ...data,
            routeStructure: [...routeStructure],
          };
        },
      }
    )
  )
);

function deserializeRouter(origin: any[]) {
  origin.forEach((_item) => {
    if (!_item.path) _item.element = <PageLayout />;
    if (_item.element && _item.path) {
      _item.element = AllRouteMappings.find(
        (_route) => _route.path === _item.path
      )?.element!;
    }
    if (_item.children) {
      _item.children = deserializeRouter(_item.children);
    }
  });
  return origin;
}

/**
 * @TODO 获取所有的权限路由
 */
export function getRouterPath(
  arr: any[],
  path: string,
  initList: TAllRouter[] = []
) {
  const targetArr: TAllRouter[] = initList || [];
  arr.forEach((item) => {
    const completePath =
      path + (item.path.indexOf('/') > -1 ? item.path : '/' + item.path);
    targetArr.push({
      name: item.name,
      completePath,
      path: item.path,
      title: item.meta.title,
      parentPath: path,
      icon: item.meta.icon || '',
    });
    if (item.children && item.children.length) {
      getRouterPath(item.children, completePath, targetArr);
    }
  });
  return targetArr;
}

/**
 * @description 采购统计
 */
export type TPurchasingDetailsParam = {
  state: boolean;
  year: string;
  supplierName?: string;
  month: string;
  applyDept?: string;
};
export const usePurchasingStore = create<{
  activeKey: string;
  setActiveKey: (val: string) => void;
  detailsParam: TPurchasingDetailsParam;
  setDetailsParam: (val: TPurchasingDetailsParam) => void;
}>((set) => ({
  activeKey: '',
  setActiveKey: (val) => set({ activeKey: val }),
  detailsParam: {
    state: false,
    year: '',
    month: '',
  },
  setDetailsParam: (val) => set({ detailsParam: val }),
}));

/**
 * @description 领用统计
 */
export type TRequisitionDetailParam = {
  state: boolean;
  time: string;
  month: string;
  storeId: number;
};
export const useRequisitionStore = create<{
  activeKey: string;
  setActiveKey: (val: string) => void;
  detailsParam: TRequisitionDetailParam;
  setDetailsParam: (val: TRequisitionDetailParam) => void;
}>((set) => ({
  activeKey: '',
  setActiveKey: (val) => set({ activeKey: val }),
  detailsParam: {
    state: false,
    time: '',
    month: '',
    storeId: -1,
  },
  setDetailsParam: (val) => set({ detailsParam: val }),
}));

/**
 * @description 侧边栏展开
 */
export const useSidebarStore = create<{
  collapsed: boolean;
  setCollapsed: (val: boolean) => void;
}>((set) => ({
  collapsed: true,
  setCollapsed: (val) => set({ collapsed: val }),
}));

/**
 * @description 仓库预警-选择的仓库
 */
export const useStoreWarningStore = create<{
  warningStoreId: number | null;
  setWarningStoreId: (val: number) => void;
  warningStoreName: string;
  setWarningStoreName: (val: string) => void;
}>((set) => ({
  warningStoreId: null,
  setWarningStoreId: (val) => set({ warningStoreId: val }),
  warningStoreName: '',
  setWarningStoreName: (val) => set({ warningStoreName: val }),
}));

/**
 * @description 低库存预警-采购申请
 */
export const useLowInventoryWarningStore = create<{
  warningList: Record<string, any>[];
  setWarningList: (val: Record<string, any>[]) => void;
}>((set) => ({
  warningList: [],
  setWarningList: (val) => set({ warningList: val }),
}));

/**
 * @description 通用入库选择物资时使用的仓库为当前库存列表选择的仓库
 */
export const useInventoryAndInStockStore = create<{
  commonStoreId: number | null;
  setCommonStoreId: (val: number) => void;
}>((set) => ({
  commonStoreId: null,
  setCommonStoreId: (val) => set({ commonStoreId: val }),
}));

/**
 * @description 当前采购申请草稿数量
 */
export const usePurchaseDraftTotal = create<{
  draftTotal: number;
  refreshDraftTotal: () => void;
}>((set) => ({
  draftTotal: 0,
  refreshDraftTotal: async () => {
    try {
      const { code, data, msg } = await GetDraft();
      if (code === codeDefinition.QUERY_SUCCESS) {
        set({ draftTotal: data[0]?.purchaseDetailsList.length || 0 });
      } else {
        message.error(msg);
      }
    } catch (error) {
      throw new Error(`${error}`);
    }
  },
}));

/**
 * @description 全局消息提示音效
 */
export const useSoundConfigStore = create<{
  configValue: boolean;
  setConfigValue: (val: boolean) => void;
}>()(
  devtools(
    persist(
      (set) => ({
        configValue: false,
        setConfigValue: (val) => set({ configValue: val }),
      }),
      {
        name: 'ejc_sound_config',
        getStorage: () => sessionStorage,
      }
    )
  )
);

/**
 * @description socket数据源
 */
export type TSocketData = {
  id: number;
  orgId: number;
  readStatus: boolean;
  templateCode: string;
  templateContent: string;
  templateId: number;
  templateNickname: string;
  templateParams: string;
  templateType: number;
  userId: number;
};
export const useSocketStore = create<{
  socketData: TSocketData[];
  setSocketData: (val: TSocketData[]) => void;
}>((set) => ({
  socketData: [],
  setSocketData: (val) => set({ socketData: val }),
}));
