import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="w-full h-[100vh] flex flex-nowrap justify-center items-center overflow-hidden">
      Loading...
    </div>
  );
};

export default Loading;
